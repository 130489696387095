import React,{useState} from "react"
import Header from '../Components/Header'
import Body from '../Components/Body'
import Join from '../Components/Join'
import Footer from '../Components/Footer'
import Getin from "../Components/Getin"
import Landingbox from "../Components/Landingbox"
import Popup from "../Components/Popup"

function Landingpage() {

  const [getTrained,setGettrained]=useState();


  const handlegetTrained=()=>{
   setGettrained(true)
 }
 
 
 const onCancle=()=>{
   setGettrained(false)
 }
 

  return (
    <div>
     <Header data={handlegetTrained} />
     <Body data={handlegetTrained}/>
     <Join data={handlegetTrained} />
     <div style={{display:getTrained ?'block':'none'}}>
       <Popup onCancle={onCancle}/>
     </div>
     <Getin/>
     <Landingbox data={handlegetTrained} />
     <Footer data={handlegetTrained}/>

    </div>
  )
}

export default Landingpage
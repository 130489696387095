import React from "react";


function Landingbox(props) {
  return (
    <div className="flex flex-row justify-center items-center" style={{backgroundColor:"#F6F6F6"}}>
      <div className="card bg-white w-[90%]  md:w-[90%] lg:w-[90%] xl:w-[80%] h-80 rounded mt-20 mb-20 ">
        <div className="bg-text w-[100%] h-80 rounded">
          <div className="flex flex-col justify-center items-center gap-5 md:gap-8">
            <div
              className="mt-5 md:mt-14 md:text-2xl lg:text-3xl xl:text-3xl "
              style={{
                color: "#FFFFFF",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "500",
              }}
            >
              Kick start your career
            </div>
            <div
              className="hidden md:block text-center md:text-sm lg:text-base xl:text-base"
              style={{
                color: "#FFFFFF",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "400",
                width: "760px",
                lineHeight: "23.5px",
              }}
            >
              Up-skilling to emerging technologies has become the need of the
              hour, with technological changes<br></br>
              shaping the career landscape. We at Vishnu Tech offers programs{" "}
              <br></br>
              in all courses with industry experts to help you up-skill, stay
              relevant & get noticed.
            </div>
            <div
              className="block md:hidden text-center text-xs"
              style={{
                color: "#FFFFFF",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "400",
                width: "250px",
                lineHeight: "23.5px",
              }}
            >
              Up-skilling to emerging technologies has become the need of the
              hour, with technological changes
              shaping the career landscape. We at Vishnu Tech offers programs{" "}
              in all courses with industry experts to help you up-skill, stay
              relevant & get noticed.
            </div>
            <div
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "24px",
              }}
              className="hover:bg-red-600  text-xs rounded-md  text_navbar_getintouch w-40 h-8 md:w-48 md:h-9 lg:w-56 lg:h-10 xl:w-56 xl:h-10"
              onClick={props.data}
            >
              Book a free online demo
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingbox;

import React from "react";

import FooterLogo from "../Assests/Vishnu Tech Logo@4x-8 2.png";

import { IoLocationSharp } from "react-icons/io5";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-full h-max border-white-100 ">
      <div className="flex flex-col  text-center items-center md:items-start md:flex-row justify-around mt-10">
        <div>
          <Link to="/landingpage"  refresh="true">
            <img
              className="w-36 h-20 ml-5 md:w-32 md:h-28 xl:w-56 xl:h-40 mt-5  "
              src={FooterLogo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex flex-col text-center md:text-left ">
          <div
            className="text-base xl:text-xl lg:text-xl md:text-base mt-5"
            style={{
              fontFamily: "Public Sans",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#1B1D1F",
              cursor: "pointer",
            }}
          >
            Courses
          </div>

          <div className="flex flex-col">
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              Full Stack Web Development
            </div>
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              AWS
            </div>
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              Devops
            </div>
            <Link 
           
             refresh="true"><div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              Python
            </div></Link>
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              Java
            </div>
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              UI Path
            </div>
          </div>
        </div>
        <div className="flex flex-col text-center md:text-left mt-5">
          <div
            className="text-base xl:text-xl lg:text-xl md:text-base "
            style={{
              fontFamily: "Public Sans",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#1B1D1F",
              cursor: "pointer",
            }}
          >
            Contact Us
          </div>

          <div className="flex flex-col  gap-2">
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base mt-2"
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              Call : +918125650485
            </div>
            <div
              className="md:text-xs text-sm lg:text-sm xl:text-base  "
              style={{
                fontFamily: "Public Sans",
                fontStyle: "",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#6D737A",
                cursor: "pointer",
              }}
            >
              Email: info@vishnutech.co.in
            </div>
          </div>
        </div>

        <div className="flex flex-col text-center md:text-left mt-5">
          <div
            className="text-base xl:text-xl lg:text-xl md:text-base"
            style={{
              fontFamily: "Public Sans",
              fontStyle: "",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#1B1D1F",
              cursor: "pointer",
            }}
          >
            Address
          </div>

          <div className="hidden md:hidden lg:block xl:hidden">
            <div className="flex flex-row gap-1">
              <IoLocationSharp size={18} className="mt-3 " />
              <div
                className=" text-sm mt-2 "
                style={{
                  fontFamily: "Public Sans",
                  fontStyle: "",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#6D737A",
                  cursor: "pointer",
                  width: "200px",
                  height: "72px",
                }}
              >
                Plot no 303, 3rd Floor, Balaji Avenue,Road no 10, Kavuri Hills,
                Madhapur.
              </div>
            </div>
          </div>

          <div className="hidden md:hidden lg:hidden xl:block">
            <div className="flex flex-row gap-1">
              <IoLocationSharp size={18} className="mt-3" />
              <div
                className=" text-base mt-2 "
                style={{
                  fontFamily: "Public Sans",
                  fontStyle: "",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#6D737A",
                  cursor: "pointer",
                  width: "300px",
                  height: "72px",
                }}
              >
                Plot no 303, 3rd Floor, Balaji Avenue, Road no 10, Kavuri Hills,
                Madhapur.
              </div>
            </div>
          </div>

          <div className="hidden md:block lg:hidden xl:hidden">
            <div className="flex flex-row gap-1">
              <IoLocationSharp size={16} className="mt-2" />
              <div
                style={{
                  fontFamily: "Public Sans",
                  fontStyle: "",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#6D737A",
                  cursor: "pointer",
                  width: "140px",
                  height: "72px",
                }}
                className="mt-1 text-xs"
              >
                {" "}
                Plot no 303, 3rd Floor, Balaji Avenue, Road no 10, Kavuri Hills,
                Madhapur.
              </div>
            </div>
          </div>

          <div className="block md:hidden lg:hidden xl:hidden">
            <div className="flex flex-row gap-1">
              <IoLocationSharp size={14} className="mt-2" />
              <div
                style={{
                  fontFamily: "Public Sans",
                  fontStyle: "",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#6D737A",
                  cursor: "pointer",
                  width: "160px",
                  height: "72px",
                }}
                className="mt-1 text-xs"
              >
                {" "}
                Plot no 303, 3rd Floor, Balaji Avenue, Road no 10, Kavuri Hills,
                Madhapur.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-center text-xs mt-5 md:text-sm mb-5"
        style={{
          fontFamily: "Public Sans",
          fontStyle: "",
          fontWeight: "400",
          lineHeight: "24px",
          color: "#939393",
        }}
      >
        Copyright ©2023 All rights reserved | vishnutech.co.in
      </div>
    </div>
  );
}

export default Footer;

import React , { useState } from "react";

import emailjs from "emailjs-com";


function Getin() {

  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");

  const handleChange = (e, name) => {
    e.preventDefault();
    setData({
      ...data,
      [name]: e.target.value,
    });
    console.log(data);
  };


  const sendEmail = async (e) => {
    e.preventDefault();
    console.log(data.name, "name");
    if (data.name && data.name != undefined) {
      if (data.users_email && data.users_email != undefined ) {
        if (data.number && data.number != undefined) {
          try {
            let res = await emailjs.sendForm(
              'service_g9tk67t', 'template_aqlz10f', e.target, 'JKpuplgAr1CfGYW5w'
            );
            console.log(res);
            if (res.status == 200) {
              alert("Your form is submited")
              setData({});
            } else {
              alert("Your form is not submited");
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          setError2("Please enter phone number ");
        }
      } else {
        setError1("Please enter email");
      }
    } else {
      setError("Please enter user name");
    }
  };



  return (
    <div className="w-full h-max flex flex-row justify-center items-center" style={{backgroundColor:"#F6F6F6"}}>
      <div
        className="hidden md:block md:w-[90%] lg:w-[90%] xl:w-[70%] shadow-lg mt-5 mb-5 bg-image "
        style={{ height: "600px", borderRadius: "50px" }}
      >
        <div
          className=" bg-gradient-to-r from-rose-600 "
          style={{ height: "600px", borderRadius: "50px" }}
        >
          <div className="flex flex-col ml-10 gap-5">
            <div
              className="md:text-xl lg:text-2xl xl:text-2xl mt-10"
              style={{
                fontFamily: "Inter",
                fontStyle: "",
                fontWeight: "700",
                lineHeight: "24px",
                color: "#F6F6F6",
              }}
            >
              To know more about "IT"<br></br>
              sector/Software.
            </div>
            <div
              className="md:text-base lg:text-xl xl:text-xl text-left"
              style={{
                fontFamily: "Inter",
                fontStyle: "",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#F6F6F6",
              }}
            >
              Clear all your queries and doubts with us that <br></br>{" "}
              constantly raised in your mind.
            </div>
            <form className="flex flex-col  gap-3" onSubmit={(e) => {
                    sendEmail(e);
                  }}
                  autoComplete="off">
              <input
                className="input_name px-3  text-xl
          py-2 md:w-72 md:h-12 lg:w-96 lg:h-14 xl:w-96 xl:h-14 text-left"
          value={data.name ? data.name : ""} 
                type="text  "
                name="name"
                id="name"
                placeholder="Your Name"
                style={{ color: "#969696", borderRadius: "10px" }}
                onChange={(e) => {
                  handleChange(e, "name");
                }}
              />
              <p style={{ color: "black",fontWeight:"500" }} className="text-sm md:text-xl ml-5 ">
                      {(data && data.name == undefined) || data.name == ""
                        ? error
                        : ""}
                    </p>
              <input
                className="input_email px-3 text-xl
          py-2 md:w-72 md:h-12 lg:w-96 lg:h-14 xl:w-96 xl:h-14 text-left"
          value={data.users_email ? data.users_email : ""} 
                type="text"
                name="users_email"
                id="email"
                placeholder="Your Email"
                style={{ color: "#969696", borderRadius: "10px" }}
                onChange={(e) => {
                  handleChange(e, "users_email");
                }}
              />
               <p style={{  color: "black",fontWeight:"500" }} className="text-sm md:text-xl ml-5 ">
                      {(data && data.users_email == undefined) ||
                      data.users_email == ""
                        ? error1
                        : ""}
                    </p>
              <input
                className="input_phone px-3 text-xl
          py-2 md:w-72 md:h-12 lg:w-96 lg:h-14 xl:w-96 xl:h-14 text-left"
                type="number"
                name="number"
                id="number"
                placeholder="Your Number"
                style={{ color: "#969696", borderRadius: "10px" }}
                onChange={(e) => {
                  handleChange(e, "number");
                }} value={data.number ? data.number : ""}
              />
               <p style={{ color: "black",fontWeight:"500"}} className="text-sm md:text-xl ml-5 ">
                      {(data && data.number == undefined) ||
                      data.number == ""
                        ? error2
                        : ""}
                    </p>
                 <button              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "24px",
              }}
              className="hover:bg-red-300 ml-10 mt-8 text-base rounded-md  text_navbar_getintouchh w-40 h-8 md:w-40 md:h-12 lg:w-40 lg:h-12 xl:w-40 xl:h-12"
              value="Send"
            >
              Send Message
            </button>
            </form>

         
          </div>
        </div>
        </div>

        <div className="block md:hidden lg:hidden xl:hidden mb-5">
        <div
        className="w-72 shadow-lg bg-image "
        style={{ height: "470px", borderRadius: "50px" }}
      >
        <div
          className=" bg-gradient-to-r from-rose-600 "
          style={{ height: "470px", borderRadius: "50px" }}
        >
          <div className="flex flex-col ml-5 md:ml-10 gap-5">
            <div
              className="md:text-xl lg:text-2xl xl:text-2xl mt-10"
              style={{
                fontFamily: "Inter",
                fontStyle: "",
                fontWeight: "700",
                lineHeight: "24px",
                color: "#F6F6F6",
              }}
            >
              To know more about "IT"<br></br>
              sector/Software.
            </div>
            <div
              className="text-sm md:text-base lg:text-xl xl:text-xl text-left"
              style={{
                fontFamily: "Inter",
                fontStyle: "",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#F6F6F6",
              }}
            >
              Clear all your queries and doubts with us that
              constantly raised in your mind.
            </div>
            <form className="flex flex-col  gap-2" onSubmit={(e) => {
                    sendEmail(e);
                  }}
                  autoComplete="off">
              <input
                className="input_name px-3
          py-2 w-56 h-10 md:w-72 md:h-12 lg:w-96 lg:h-14 xl:w-96 xl:h-14 text-left"
                type="name"
                name="name"
                id="name"
                placeholder="Your Name"
                style={{ color: "#969696", borderRadius: "10px" }}
                value={data.name ? data.name : ""} 
                onChange={(e) => {
                                 handleChange(e, "name");
                               }}
              />
                <p style={{ color: "black",fontWeight:"500" }} className="text-sm md:text-xl ml-5 ">
                      {(data && data.name == undefined) || data.name == ""
                        ? error
                        : ""}
                    </p>
              <input
                className="input_email px-3
          py-2 w-56 h-10 md:w-72 md:h-12 lg:w-96 lg:h-14 xl:w-96 xl:h-14 text-left"
                type="name"
                name="users_email"
                id="email"
                placeholder="Your Email"
                style={{ color: "#969696", borderRadius: "10px" }}
                value={data.users_email ? data.users_email : ""} 
 onChange={(e) => {
                  handleChange(e, "users_email");
                }}
              />
              <p style={{  color: "black",fontWeight:"500" }} className="text-sm md:text-xl ml-5 ">
                      {(data && data.users_email == undefined) ||
                      data.users_email == ""
                        ? error1
                        : ""}
                    </p>
              <input
                className="input_phone px-3
          py-2 w-56 h-10 md:w-72 md:h-12 lg:w-96 lg:h-14 xl:w-96 xl:h-14 text-left"
                type="number"
                name="number"
                id="number"
                placeholder="Your Number"
                style={{ color: "#969696", borderRadius: "10px" }}
                onChange={(e) => {
                  handleChange(e, "number");
                }} value={data.number ? data.number : ""}
              />
              
 <p style={{ color: "black",fontWeight:"500"}} className="text-sm md:text-xl ml-5 ">
                      {(data && data.number == undefined) ||
                      data.number == ""
                        ? error2
                        : ""}
                    </p>
                 <button
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "24px",
              }}
              className="hover:bg-red-300 ml-10 mt-5 md:mt-8 text-sm md:text-base rounded-md  text_navbar_getintouchh w-32 h-10 md:w-40 md:h-12 lg:w-40 lg:h-12 xl:w-40 xl:h-12"
              value="Send"
            >
              Send Message
            </button>
            </form>

         
          </div>
        </div>
        </div>
        </div>

    </div>
  );
}

export default Getin;

const Data=[
     
          {
               id:1,
               question:"Basics of Python",
               answer:[
                 {ques:"Motivation & Applicability to various domains ,Installation & Setting up path Input / Output"},
                 {ques:"Keywords and Identifiers, Variables and Data Types"},
                 {ques:"Conditional Statements"},
                 {ques:"Looping, Control flow (along with loops)"},
                 {ques:"Strings and Features"},
                 {ques:"String Manipulation, Functions"},
               ]
             },

             {
              id:2,
              question:"Python Collections and sequences",
              answer:[
                {ques:"Lists and Features"},
                {ques:"Keywords and Identifiers, Variables and Data Types"},
                {ques:"Conditional Statements"},
                {ques:"Looping, Control flow (along with loops)"},
                {ques:"Strings and Features"},
                {ques:"String Manipulation, Functions"},
              ]
            },
            {
              id:3,
              question:"Working with Python collections",
              answer:[
                {ques:"Working with Lists and Tuples"},
                {ques:"Working with Dictionaries"},
                {ques:"Working with Sets and Frozen Sets"},
                           
              ]
            },
            {
              id:4,
              question:"Python Functional programming",
              answer:[
                {ques:"Types of functions"},
                {ques:"Function Arguments"},
                {ques:"Anonymous functions"},
                {ques:"Special functions (map , reduce , filter)"},
              ]
            },
            {
              id:5,
              question:"Python file Handling",
              answer:[
                {ques:"Files"},
                {ques:"File operations Part - 1"},
                {ques:"File operations Part - 2"},
                {ques:"Handling excel , csv files"},
              ]
            },
            {
              id:6,
              question:"Python Modules and packages",
              answer:[
                {ques:"Modules"},
                {ques:"Importing module"},
                {ques:"Packages"},
                {ques:"Math , Random and OS module"},
              ]
            },
            {
              id:7,
              question:"Classes in Python",
              answer:[
                {ques:"Classes"},
                {ques:"Objects"},
                {ques:"Constructors and Destructors"},
                {ques:"Types of Methods"},
              ]
            },
            {
              id:8,
              question:"Oops in Python",
              answer:[
                {ques:"Inheritance"},
                {ques:"Polymorphism"},
                {ques:"Magic methods"},
                {ques:"Operator Overloading"},
                {ques:"Data hiding(Abstraction)"},
                {ques:"Encapsulation"},
              ]
            },
            {
              id:9,
              question:"Database Programming in python",
              answer:[
                {ques:"Introduction of MySQL"},
                {ques:"Connection and Queries"},
                {ques:"PISQL/MySQL"},
                {ques:"Multithreading"},
                {ques:"Regular Expressions"},
                
              ]
            },
            {
              id:10,
              question:"GITHUB Version Control",
              answer:[
                {ques:"Creating a Repository"},
                {ques:"Files State"},
                {ques:"Commit and undo Commit"},
                {ques:"File Removing"},
                {ques:"File Merging"},
                {ques:"Git Remove,PULL,PUSH,CLONE"},
                
              ]
            },
            {
              id:11,
              question:"Exception handling in python",
              answer:[
                {ques:"Exception Handling"},
                {ques:"Try & finally clause"},
                {ques:"User-Defined Exceptions"},
                
              ]
            },
            {
              id:12,
              question:"Advanced python",
              answer:[
                {ques:"Tkinter GUI Programming"},
                {ques:"or"},
                {ques:"Web Scraping(BS4)"},
                
              ]
            },
            {
              id:13,
              question:"Python for Data Science",
              answer:[
                {ques:"Numpy"},
                {ques:"Pandas"},
                {ques:"Matplotlib"},
                {ques:"Seaborn"},
                {ques:"jupyter Notebook"},
                
              ]
            },

            {
              id:14,
              question:"Python with Django",
              answer:[
                {ques:"Django Web Framework"},
                {ques:"Features of Django"},
                {ques:"Installing Django"},
                {ques:"MVC model"},
                {ques:"Views"},
                {ques:"URL Mapping"},
              ]
            },
            {
              id:15,
              question:"Python with Flask",
              answer:[
                {ques:"Flask Web Framework"},
                {ques:"Working with veny"},
                {ques:"Installing Flask"},
                {ques:"Creating a Flask app"},
                {ques:"HTTP GET and POST Request Methods in Flask"},
              ]
            },
            
             
             
             
    
   ]

export default Data;
import React, { useState } from "react";

import Logo from "../Assests/Vishnu Tech Logo@4x-8 1.png";

import { Transition } from "@headlessui/react";


import {Link} from "react-router-dom"


function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="w-full h-max border-solid border-2 border-white-100"
      style={{ backgroundColor: "#FEF3E9" }}
    >
      <div className="flex flex-row justify-between items-center  xl:mx-20 lg:mx-16">
        <div className="flex flex-row items-center mb-5 mt-5">
          <div>
            <Link to="/landingpage" refresh="true"><img
              className="w-20 h-14 ml-5 md:w-16 md:h-12 xl:w-24 xl:h-16  "
              src={Logo}
              alt="logo"
              style={{cursor:"pointer"}}
            /></Link>
          </div>
        </div>
        <div>
          <div className="hidden md:block">
            <div className="ml-10 md:mr-10 md:gap-1  xl:mr-12 lg:mr-4 flex items-baseline md:space-x-0 lg:space-x-6 xl:space-x-6">
            <Link to="/landingpage" refresh="true"><div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#F8961D",
                  cursor: "pointer",
                }}
                className=" px-3 py-2 rounded-md md:text-base text-sm font-medium"
              >
                Home
              </div></Link>

              <div className=" dropdown flex flex-row  justify-center  px-3 py-2 rounded-md  items-center">
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "#F8961D",
                    cursor: "pointer",
                  }}
                  to="/Admissions"
                  className=" text-sm md:text-base"
                >
                  Courses
                  <i
                    style={{ fontSize: "24px" }}
                    className="ml-1 fa">&#xf0d7;</i>
                </div>

                <div
                  class="dropdown-content text-base"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "#F8961D",
                    cursor: "pointer",
                  }}
                >
                  <Link onClick={props.data}>Python</Link>
                  <Link onClick={props.data}>Java</Link>
                  <Link onClick={props.data}>FullStack</Link>
                  <Link onClick={props.data}>AWS</Link>
                  <Link onClick={props.data}>Devops</Link>
                  <Link onClick={props.data}>Azure Devops</Link>
                  <Link onClick={props.data}>UI Path</Link>
                  <Link onClick={props.data}>Testing</Link>
                  <Link onClick={props.data}>Salesforce</Link>
                  <Link onClick={props.data}>Power BI</Link>
                  <Link onClick={props.data}>Tableau</Link>
                </div>
              </div>

              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#F8961D",
                  cursor: "pointer",
                }}
                className=" px-3 py-2 rounded-md text-sm md:text-base"
                onClick={props.data}
              >
                About Us
              </div>

              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#F8961D",
                  cursor: "pointer",
                }}
                className=" px-3 py-2 rounded-md text-sm md:text-base"
                onClick={props.data}
              >
                Get Trained
              </div>

              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
                className="hover:bg-red-600 px-2 py-2 text-sm md:text-xs xl:text-sm lg:text-sm rounded-md  text_navbar_getintouch md:w-24 md:h-9 lg:w-28 lg:h-9  xl:w-28 xl:h-10"
                onClick={props.data}
              >
                Get in Touch
              </div>
            </div>
          </div>
          <div className="mr-2   flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className=" inline-flex items-center justify-center p-2 rounded-md "
              aria-controls="mobile-menu"
              aria-expanded="false"
              style={{ backgroundColor: "#FEF3E9" }}
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu">
            <div
              ref={ref}
              className="px-2 pt-2 pb-3 space-y-1 sm:px-3"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "22px",
                color: "#F8961D",
              }}
            >
              <Link to="/landingpage"  refresh="true"><div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                className=" hover:bg-pink-200 hover:text-white block px-3 py-2 rounded-md text-base"
              >
                Home
              </div></Link>

              <div className=" dropdown gap-5 flex flex-row justify-center hover:bg-slate-50 px-3 py-2 rounded-md  items-center">
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#F8961D",
                  }}
                  to="/Admissions"
                  className=" text-sm"
                >
                  Courses
                  <i style={{ fontSize: "24px" }} className="ml-1 fa">&#xf0d7;</i>
                </div>

                <div
                  class="dropdown-content "
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "#FEF3E9",
                  }}
                >
                  <Link onClick={props.data}>Python</Link>
                  <Link onClick={props.data}>Java</Link>
                  <Link onClick={props.data}>FullStack</Link>
                  <Link onClick={props.data}>AWS</Link>
                  <Link onClick={props.data}>Devops</Link>
                  <Link onClick={props.data}>Azure Devops</Link>
                  <Link onClick={props.data}>UI Path</Link>
                  <Link onClick={props.data}>Testing</Link>
                  <Link onClick={props.data}>Salesforce</Link>
                  <Link onClick={props.data}>Power BI</Link>
                  <Link onClick={props.data}>Tableau</Link>
                </div>
              </div>

              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                className=" hover:bg-pink-200 hover:text-white block px-3 py-2 rounded-md text-base"
                onClick={props.data}
              >
                Carrer support
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                to="/Contactus"
                className=" hover:bg-pink-200 hover:text-white block px-3 py-2 rounded-md text-base"
                onClick={props.data}
              >
                Get Trained
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
                className="hover:bg-red-600 ml-2 text-xs rounded-md  text_navbar_getintouch w-24 h-8"
                onClick={props.data}
              >
                Get in Touch
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default Header;

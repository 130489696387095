import React from 'react'

import Right from "../Assests/noun-correct-5373276 1.png"

function Pythonoverview() {
     const imageList=[
          {
               id:1,
               src:Right,
               description:"20+ assisted practices on all modules"
          },
          {
               id:2,
               src:Right,
               description:"Industry-recognized course completion certificate"
          },
          {
               id:3,
               src:Right,
               description:"5 lesson-end knowledge checks and 1 real-life course-end project"
          },
          {
               id:4,
               src:Right,
               description:"8X higher interaction in live online classes conducted by industry experts"
          },

     ]
  return (
    <div className='w-full h-max' style={{backgroundColor:"#FFFFFF"}}>
     <div className='flex flex-col justify-center items-center gap-8 md:gap-10'>
     <div className='text-xl md:text-xl lg:text-2xl xl:text-3xl mt-10' style={{color: "#28A992",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "600",}}>
          Python Course Overview
          </div>
          <div
              className="hidden md:block text-center md:text-sm lg:text-base xl:text-base"
              style={{
                color: "#818181",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "400",
                width: "760px",
                lineHeight: "23.5px",
              }}
            >
             Python training in Austin covers the principles of Python and the methods of applying<br></br> them to real-world applications. The lesson-end projects, curriculum and assignments in<br></br> the Python course in Austin covers conditional statements, strings, Python data <br></br>operations, error handling, web scraping, shell scripting, and Django.
            </div>
            <div
              className="block md:hidden text-center text-sm"
              style={{
                color: "#818181",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "400",
                width: "250px",
                lineHeight: "23.5px",
              }}
            >
              
              Python training in Austin covers the principles of Python and the methods of applying them to real-world applications. The lesson-end projects, curriculum and assignments in the Python course in Austin covers conditional statements, strings, Python data operations, error handling, web scraping, shell scripting, and Django.
            </div>
     </div>

     <div className=' flex flex-row justify-center items-center  mt-10 mb-20'>
          <div className='w-[90%] md:w-[90%] lg:w-[90%] xl:w-[80%] md:h-80 shadow-lg ' style={{backgroundColor:"#EAFFFB"}}>

               <div className='hidden md:block flex flex-col text-xl xl:ml-10 mt-8 gap-8'>
                    <div className='ml-10' style={{ fontFamily: "Public Sans",
              fontWeight: "500",
              lineHeight: "24px",
              color:"#686868"}}>
                    Python Certification Key Features
                    </div>
                    <div className='flex flex-col md:flex-row justify-center mt-5 md:gap-2 lg:gap-10 xl:gap-20'>
                    {imageList.map((image)=>(
                    <div className='card shadow-lg w-44 md:w-40 md:h-48 lg:w-44 lg:w-44 xl:w-48 xl:h-48' style={{backgroundColor:"#FFFFFF",borderRadius:"10px"}}>
                         

                              <div className='flex flex-col justify-center items-center text-center gap-1 mt-5'>
                         <img src={image.src} className='w-16 h-16' alt=""/>
                         <div  className="text-xs" style={{ fontFamily: "Public Sans",
              fontWeight: "400",
              lineHeight: "24px",
              width:"140px",
              color:"#686868"}}>{image.description}</div>
                         </div>

                        
                         
                     
                    </div>
                     ))}
                    </div>
                  
               </div>
               <div className='block md:hidden flex flex-col text-xl mt-5 gap-5'>
                    <div className='ml-10' style={{ fontFamily: "Public Sans",
              fontWeight: "500",
              lineHeight: "24px",
              color:"#686868"}}>
                    Python Certification Key Features
                    </div>
                    <div className='flex flex-col justify-center items-center gap-10 mb-10'>
                    {imageList.map((image)=>(
                    <div className='card shadow-lg w-44 h-44' style={{backgroundColor:"#FFFFFF",borderRadius:"10px"}}>
                         

                              <div className='flex flex-col justify-center items-center text-center gap-1 mt-5'>
                         <img src={image.src} className='w-8 h-8' alt=""/>
                         <div  className="text-xs" style={{ fontFamily: "Public Sans",
              fontWeight: "400",
              lineHeight: "24px",
              width:"140px",
              color:"#686868"}}>{image.description}</div>
                         </div>

                        
                         
                     
                    </div>
                     ))}
                    </div>
                  
               </div>
          </div>
     </div>
    </div>
  )
}

export default Pythonoverview
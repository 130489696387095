
import './App.css';

import Landingpage from './Finalpages/Landingpage';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PythonPage from './Finalpages/PythonPage';
import Pythonciriculam from './Components/Pythonciriculam';



function App() {
  return (


    <BrowserRouter>
    <Routes>
      <Route excat path="/" element={<Landingpage/>}/>
      <Route path="/landingpage" element={<Landingpage/>}/>
      <Route path="/pythonpage" element={<PythonPage/>}/>
    </Routes>
    </BrowserRouter>


  );
}

export default App;

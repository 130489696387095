import React from "react";


import Photo from "../Assests/Hero Section Image.png"
function Body(props) {
  return (
    <div className="w-full h-full bg-gradient-to-r from-yellow-200  to-green-300">
      <div className="flex flex-col gap-5 items-center md:flex-row md:justify-evenly md:items-center ">
        <div className="flex flex-col items-center md:items-start  gap-6"> 
          <div className="text-xl md:text-left text-center md:text-2xl lg:text-4xl mt-5" style={{
              fontFamily: "Public Sans",
              fontWeight: "700",
              lineHeight: "24px",
              color: "#28A992",
              cursor: "pointer",
            }}>The Right Path To Success</div>
          <div className="hidden md:block lg:hidden xl:hidden text-left text-sm md:text-base" style={{
              fontFamily: "Public Sans",
              fontWeight: "400",
              color: "#797979",
              width:"320px",
              height:"80px",
              cursor: "pointer",}}>
            We provide support to our learners through live projects for
            practical experience and facilitate placements in renowned
            companies.
          </div>
          <div className="hidden hidden lg:block xl:block text-sm md:text-xl" style={{
              fontFamily: "Public Sans",
              fontWeight: "400",
             
              color: "#797979",
              width:"540px",
              height:"80px",
              cursor: "pointer",}}>
            We provide support to our learners through live projects for
            practical experience and facilitate placements in renowned
            companies.
          </div>
          <div className="block text-center md:hidden lg:hidden xl:hidden text-xs md:text-xl" style={{
              fontFamily: "Public Sans",
              fontWeight: "400",
             
              color: "#797979",
              width:"250px",
              height:"60px",
              cursor: "pointer",}}>
            We provide support to our learners through live projects for
            practical experience and facilitate placements in renowned
            companies.
          </div>
          <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
                className="hover:bg-red-600  text-xs rounded-md  text_navbar_getintouch w-24 h-8 md:w-24 md:h-9 lg:w-28 lg:h-10 xl:w-28 xl:h-10"
                onClick={props.data}
                
              >
                Get in Touch
              </div>
        </div>
    
        <div className="w-80 h-96  md:w-[38%] md:h-[100%] md:mt-10 mb-5">
          <img src={Photo} alt=""/>
        </div>
      </div>
    </div>
   
  );
}

export default Body;

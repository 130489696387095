import React from 'react'

import PythonLogo from "../Assests/PythonLogo.png"

function Python(props) {
  return (
    <div className='w-full h-max bg-gradient-to-b from-rose-200  to-green-200'>

     <div className='flex flex-col gap-5 justify-center text-center items-center md:flex-row md:justify-around md:items-center'>
          <div className='flex flex-col gap-5'>
           <div className='mt-5 text-2xl md:text-2xl lg:text-4xl xl:text-4xl' style={{
              fontFamily: "Public Sans",
              fontWeight: "700",
              lineHeight: "24px",
              color: "#D42B4D",
            }}>
           Python Training
           </div>
           <div className='hidden md:hidden lg:block xl:block' style={{
              fontFamily: "Public Sans",
              width:"520px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#797979",}}>
           Basics of Python, Data Operations, Django, Conditional Statements, and Shell Scripting. Hands-on programming experience, prepare to be a professional with a great Python programming career. 
           </div>
           <div className='hidden md:block lg:hidden xl:hidden text-sm' style={{
              fontFamily: "Public Sans",
              width:"420px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#797979",}}>
           Basics of Python, Data Operations, Django, Conditional Statements, and Shell Scripting. Hands-on programming experience, prepare to be a professional with a great Python programming career. 
           </div>
           <div className='block md:hidden lg:hidden xl:hidden text-sm' style={{
              fontFamily: "Public Sans",
              width:"280px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#797979",}}>
           Basics of Python, Data Operations, Django, Conditional Statements, and Shell Scripting. Hands-on programming experience, prepare to be a professional with a great Python programming career. 
           </div>
           <div className='flex flex-col justify-center items-center md:flex-row gap-5'>
           <div
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "24px",
              }}
              className="hover:bg-red-600 text-xs md:text-xs rounded-md  text_navbar_getintouch w-40 h-9 md:w-40 md:h-9 lg:w-44 lg:h-10 xl:w-44 xl:h-10"
              onClick={props.data}
            >
              Schedule Online Demo
            </div>
            <div
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "24px",
                border:"1px",
                borderColor:"#D42B4D",
                borderStyle:"solid"
              }}
              className="hover:bg-red-300 text-xs md:text-xs lg:text-sm xl:text-sm rounded-md  text_navbar_getintouchs w-40 h-9 md:w-40 md:h-10 lg:w-48 lg:h-10 xl:w-48 xl:h-10"
              onClick={props.data}
            >
              Contact Course Advisor
            </div>
           </div>
          </div>
          <div className="w-64 md:w-[25%] lg:w-[30%] xl:w-[30%] mb-10 mt-10" >
               <img src={PythonLogo} className='hidden md:hidden lg:hidden xl:block' style={{height:"360px"}} alt=""/>
               <img src={PythonLogo} className='hidden md:hidden lg:block xl:hidden' style={{height:"340px"}} alt=""/>
               <img src={PythonLogo} className='hidden md:block lg:hidden xl:hidden' style={{height:"250px"}} alt=""/>
               <img src={PythonLogo} className='block md:hidden lg:hidden xl:hidden' style={{height:"200px"}} alt=""/>
          </div>
     </div>
    </div>
  )
}

export default Python
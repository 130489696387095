import React,{useState} from 'react'
import Data from "../Components/Data"
function Pythonciriculam() {

  const [selected,setSelected]=useState(null)

  const toggle=(i)=>{
if(selected==i){
  return setSelected(null)

}
setSelected(i)
  }

  return (
    <div className='w-full h-max' style={{backgroundColor:"#FFFFFF"}}>
      
     <div className='text-xl md:text-3xl text-center mt-10' style={{ fontFamily: "Public Sans",
              fontWeight: "700",
              lineHeight: "24px",
              color:"#797979"}}>
     Python Course Curriculum
     </div>
     <div className='flex flex-row justify-center items-center mb-10 outer-container '>

          <div className='card shadow-lg rounded-md w-72 md:w-[80%] lg:w-[68%] xl:w-[45%] mt-10 inner-container  ' style={{height:"500px",backgroundColor:"#FFFFFF",borderRadius:"20px"}}>
          {Data?.map((v,i)=>{
        return(
          <div className='hidden md:block' >
              <div className=' flex flex-row justify-around items-center  mt-10' onClick={()=>toggle(i)}>
              <div style={{fontFamily: "Public Sans",
                fontWeight: "600",
                width:"300px",
                color:"#28A992"}}>{v.question}</div>
  <div className='text-3xl'>{selected=== i? '+':'-'}</div>
              </div>


{v?.answer?.map((val)=>{
            return(
              <div className={selected===i?'content show':'content'} style={{}}>
                <ul className='flex flex-row justify-center items-center md:ml-16 lg:ml-10 xl:ml-10'>
                  <li style={{fontFamily: "Public Sans",
                fontWeight: "400",
                width:"550px",
            
                color:"#6F6C90"}}>{val?.ques}</li>
                  
                </ul>
              
                
                </div>
            )
          })}
        <div className=' flex flex-row justify-center items-center  mt-3' style={{borderColor:"#D9DBE9"}}>
          <div className='border border-solid w-[80%]'></div>
        </div>
          </div>
          );
           
      })}

{Data?.map((v,i)=>{
        return(
          <div className='block md:hidden' >
              <div className=' flex flex-row justify-around items-center mt-10' onClick={()=>toggle(i)}>
              <div style={{fontFamily: "Public Sans",
                fontWeight: "600",
                width:"180px",
                color:"#28A992"}} className='text-xs'>{v.question}</div>
  <div className='text-xl'>{selected=== i? '+':'-'}</div>
              </div>


{v?.answer?.map((val)=>{
            return(
              <div className={selected===i?'content show':'content'} style={{}}>
                <ul className='flex flex-row justify-center items-center ml-5'>
                  <li style={{fontFamily: "Public Sans",
                fontWeight: "400",
                width:"320px",
                color:"#6F6C90"}} className='text-xs'>{val?.ques}</li>
                  
                </ul>
             
                
                </div>
            )
          })}
        <div className=' flex flex-row justify-center items-center  mt-3' style={{borderColor:"#D9DBE9"}}>
          <div className='border border-solid w-[80%]'></div>
        </div>
          </div>
          );
           
      })}

           
          
        

          </div>
          
     </div>
    </div>
  )
}

export default Pythonciriculam
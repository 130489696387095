import React,{useState} from "react"
import Header from '../Components/Header'
import Python from '../Components/Python'
import JobRole from '../Components/JobRole'
import Footer from '../Components/Footer'
import Landingboxtwo from '../Components/Landingboxtwo'
import Popup from "../Components/Popup"
import Benifits from "../Components/Benifits"
import Pythonproject from "../Components/Pythonproject"

function PythonPage() {
  const [getTrained,setGettrained]=useState();


  const handlegetTrained=()=>{
   setGettrained(true)
 }

 const onCancle=()=>{
  setGettrained(false)
}
  return (
    <div>
     <Header data={handlegetTrained} />
     <Python data={handlegetTrained}/>
     <JobRole data={handlegetTrained}/>
     <div style={{display:getTrained ?'block':'none'}}>
       <Popup onCancle={onCancle}/>
     </div>

     <Benifits/>
     <Pythonproject/>
     <Landingboxtwo data={handlegetTrained}/>
     <Footer data={handlegetTrained}/>
    </div>
  )
}

export default PythonPage;
import React from 'react'

import Doc from "../Assests/noun-project-3323792 1.png"

function Pythonproject() {
     const Document=[
          {
               id:1,
               image:Doc,
               Disc:"PROJECT I",
               Disctwo:"Python Decorators",
               Discthree:"Implement a Python decorator that should take whatever the decorated function returns, and writes it to a file in a new line."
               
          },
          {
               id:1,
               image:Doc,
               Disc:"PROJECT II",
               Disctwo:"Manage the Password",
               Discthree:"Develop a new version of a password manager that can keep track of all the passwords that have been set for the respective accounts."
               
          },
          {
               id:1,
               image:Doc,
               Disc:"PROJECT III",
               Disctwo:"Patient Management Application",
               Discthree:"Write an application in Django for a hospital receptionist that has features, including checking if the patient is already registered. If not register the patient."
               
          },
     ]
  return (
    <div className='w-full h-max mt-10 mb-20' style={{backgroundColor:"#FFFFFF"}}>

     <div className='text-3xl mt-10 ml-20' style={{ fontFamily: "Public Sans",
         fontWeight: "700",
         
         color:"#707070"}} >Live Projects</div>


         <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 justify-items-center  gap-3 md:gap-5 '>
          {Document.map((Doc)=>(
       <div className='card shadow-lg rounded-md w-64 h-64 mt-20 ' style={{backgroundColor:"#FFFFFF",border:1,position:"relative",borderColor:"#A1A1A1",borderStyle:"ridge"}}>
       <div className='flex flex-col justify-center items-center mt-20 gap-1'>
            <div className='text-sm' style={{ fontFamily: "Public Sans",
 fontWeight: "500",
 color:"#28A992"}}>{Doc.Disc}</div>
            <div className='text-base text-center' style={{ fontFamily: "Public Sans",
 fontWeight: "600",
 width:"200px",
 
 color:"#D42B4D"}}>{Doc.Disctwo}</div>
            <div className='text-xs text-center' style={{ fontFamily: "Public Sans",
 fontWeight: "400",
 width:"200px",
 color:"#818181"}}>{Doc.Discthree}</div>
       </div>
       <div className='card shadow-lg rounded-md w-24 h-24'style={{backgroundColor:"#FFFFFF",position:"absolute",zIndex:1,borderRadius:"60px",left:"80px",top:"-50px",border:1,borderColor:"#A1A1A1",borderStyle:"solid"}}>

            <div className='flex flex-row justify-center items-center'>
            <img src={Doc.image} className='w-14 h-14 mt-5' alt=""/>
            </div>
            
       </div>
  </div>
               ))}
         
         </div>

    </div>
  )
}

export default Pythonproject
import React from 'react'

import Fullstack from "../Assests/Fullstack.png"
import Aws from "../Assests/Aws.png"
import Devops from "../Assests/Devops.png"
import Python from "../Assests/Python.png"
import Java from "../Assests/Java.png"
import Uipath from "../Assests/UIpath.png"
import {Link} from "react-router-dom"



function Join() {

  
     const imageList=[
          {
               id:1,
               src:Fullstack,
               title:"FullStack Web Development",
               description:"The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of software development skills.Complete the course to get an assured job.",
               linkTo:"/landingpage"
               
          },
          {
               id:2,
               src:Aws,
               title:"AWS",
               description:"AWS provides a set of flexible services designed to enable companies to more rapidly and reliably build and deliver products using AWS and DevOps practices.",
              

          },
          {
               id:3,
               src:Devops,
               title:"Devops",
               description:"A perfect transition is waiting for you and this course will help achieve just that. We will covert all the complex setup, handling of repositories and all the ways to manage your projects."
          },
          {
               id:4,
               src:Python,
               title:"Python",
               description:"Python is easy to learn. Simplicity is the single biggest reason you should take a python scripting course. It doesn’t have complicated syntax and rules. It’s also easier to set up where you don’t need to deal with classpath problems or compiler issues like in Java & C++.",
               linkTo:"/pythonpage"
          },
          {
               id:5,
               src:Java,
               title:"Java",
               description:"Java is easy to learn, object-oriented, and platform-independent and is, therefore, one of the most popular programming languages.  Even though Java is one of the oldest languages, it is still a high-paying career."
          },
          {
               id:6,
               src:Uipath,
               title:"UI Path",
               description:"UiPath is a most popular Robotic Process Automation tool in 2023 it is used for Windows desktop applications and web application automation. It is used to automate redundant tasks and eliminates the user (human) interaction."

          }
     ];
  return (
    <div className='w-full h-max' style={{backgroundColor:"#F6F6F6"}}>

     <div className='flex flex-col justify-center items-center gap-8 md:gap-10'>
          <div className='text-3xl md:text-4xl lg:text-4xl xl:text-5xl mt-10' style={{color: "#28A992",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "600",}}>
          Join Us
          </div>
          <div
              className="hidden md:block text-center md:text-sm lg:text-base xl:text-base"
              style={{
                color: "#818181",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "400",
                width: "760px",
                lineHeight: "23.5px",
              }}
            >
             Our main focus towards providing in–depth and quality training on all latest and<br></br> upgrading technologies with professional and well experienced trainers.<br></br>
Using new skills current employment will be replaced. Still if you are searching for<br></br> justifications to upskill yourself in to upgraded technologies
            </div>
            <div
              className="block md:hidden text-center text-sm"
              style={{
                color: "#818181",
                fontFamily: "Public sans",
                fontStyle: "normal",
                fontWeight: "400",
                width: "250px",
                lineHeight: "23.5px",
              }}
            >
              
             Our main focus towards providing in–depth and quality training on all latest and upgrading technologies with professional and well experienced trainers.
Using new skills current employment will be replaced. Still if you are searching for justifications to upskill yourself in to upgraded technologies
            </div>

            <div className='mb-20  grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 justify-items-center items-center gap-10 md:gap-14 lg:gap-10  xl:gap-20'>
          {imageList.map((image)=>(
                <Link to={image.linkTo}  refresh="true"><div className="w-72 h-96 md:w-72 md:h-96 lg:w-72 lg:h-96 xl:w-72 xl:h-96  shadow-lg md:mt-10" style={{borderRadius:"50px",backgroundColor:" #ffffff"}}>
                <div className=" flex flex-col align-items-center w-[100%] h-[100%]" style={{position:"relative",borderRadius:"1px",cursor:"pointer"}}>
                  <img
                    className=" w-42 h-40 md:w-42 md:h-40"
                    src={image.src}
                    alt="not_visible"
                    style={{borderTopLeftRadius:"50px",borderTopRightRadius:"50px"}}
                  />
                  <div className='w-40 text-xl ml-5 mt-5 '  style={{
                    color: "#D42B4D",
                    fontStyle: "normal",
                   //  fontWeight: "700",
                    fontWeight:"bolder"
                  }}>
                   {image.title}
                  </div>
                  <div className='ml-5 mt-3 text-sm' style={{
                    color: "#696969",
                    fontStyle: "normal",
                    fontWeight: "400",
                    width:"250px"
                  
                  }} >
                  {image.description}
                  </div>
                </div>
              </div></Link>
              
          ))}
         
          </div>
           
       
     </div>
     
   

    </div>

  )
}

export default Join
import React from "react";

import SE from "../Assests/SE.png";

import PHY from "../Assests/PYD.png";

import SD from "../Assests/SD.png";

import DA from "../Assests/DA.png";

import RA from "../Assests/RA.png";
import Tools from "./Tools";
import Pythonoverview from "./Pythonoverview";
import Pythonciriculam from "./Pythonciriculam";

function JobRole() {
  const imageList = [
    {
      id:1,
      src:SE,
      description:"Software Engineer",
    },
    {
      id:2,
      src:PHY,
      description:"Python Developer",
      Link:"/pythonpage"
    },
    {
      id:3,
      src:SD,
      description:"Software Developer",
    },
    {
      id:4,
      src:DA,
      description:"Data Analyst",
    },
    {
      id:5,
      src:RA,
      description:"Research Analyst",
    },
  ];
  return (
    <div className="w-full h-max bg-white ">
      <div className="flex flex-col justify-center items-center mt-10 gap-10">
        <div
          className="text-2xl md:text-2xl lg:text-2xl xl:text-3xl mt-10"
          style={{
            color: "#28A992",
            fontFamily: "Public sans",
            fontStyle: "normal",
            fontWeight: "600",
          }}
        >
          Job Roles
        </div>
        <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-14   mb-5">
        {imageList.map((image) => (
          <div
            className=" card shadow-lg rounded-md w-36 h-32"
            style={{ backgroundColor: "#FCFCFC", borderRadius: "20px",cursor:"pointer" }}
          >
          
              <div className="flex flex-col justify-center items-center mt-5 gap-2" style={{cursor:"pointer"}}>
                <div>
                  <img src={image.src} className="w-14 h-14" alt="" />
                </div>
                <div
                  className="text-xs"
                  style={{
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#5C5C5C",
                  }}
                >
                  {image.description}
                </div>
              </div>
          
          </div>
            ))}
        </div>
      </div>
    <Pythonciriculam/>
      <Tools/>
      <Pythonoverview/>
    </div>
    
  );
}

export default JobRole;

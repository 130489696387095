import React from 'react'


import Pythonlogo from "../Assests/Group 37135.png"
import Graph from "../Assests/Group 37136 (1).png"
import Company from "../Assests/Group 37137.png"
function Benifits() {

     const Box=[
          {
               id:1,
               image:Pythonlogo,
               content:"Designation"
          },
          {
               id:2,
               image:Graph,
               content:"Annual Salary"
          },
          {
               id:3,
               image:Company,
               content:"Hiring Companies"
          }
     ]

     
  return (
     <div className=' flex flex-row justify-center items-center  mt-10 mb-20'>
     <div className='w-[90%] md:w-[90%] lg:w-[90%] xl:w-[80%]  shadow-lg ' style={{backgroundColor:"#EAFFFB"}}>

          <div className='flex flex-col text-2xl ml-5 mt-8 '>
               <div className='hidden md:block  ml-5' style={{ fontFamily: "Public Sans",
         fontWeight: "500",
         lineHeight: "24px",
         color:"#686868"}}>
               Benefits
               </div>

               <div className='flex flex-col  justify-center items-center text-center'>
          <div className='block md:hidden' style={{ fontFamily: "Public Sans",
         fontWeight: "400",
         lineHeight: "24px",
         color:"#686868"}}>
               Benefits
               </div>

               <div className='block md:hidden lg:hidden xl:hidden text-sm mt-5' style={{ fontFamily: "Public Sans",
         fontWeight: "400",
         lineHeight: "20px",
         width:"220px",
         color:"#686868"}}>
               According to the 2019 StackOverflow developer survey, Python is the second most popular programming language. Data Scientists, AI and Machine Learning Engineers prefer Python training in Austin for enhancing their career. Programmers with this Python course in Austin earn an annual salary of $115,000. 
               </div>
          </div>
               

               <div className='hidden md:block lg:hidden xl:hidden text-sm ml-5 mt-5' style={{ fontFamily: "Public Sans",
         fontWeight: "400",
         lineHeight: "24px",
         width:"600px",
         color:"#686868"}}>
               According to the 2019 StackOverflow developer survey, Python is the second most popular programming language. Data Scientists, AI and Machine Learning Engineers prefer Python training in Austin for enhancing their career. Programmers with this Python course in Austin earn an annual salary of $115,000. 
               </div>
               <div className='hidden md:hidden lg:block xl:hidden text-base ml-5 mt-5' style={{ fontFamily: "Public Sans",
         fontWeight: "400",
         lineHeight: "24px",
         width:"780px",
         color:"#686868"}}>
               According to the 2019 StackOverflow developer survey, Python is the second most popular programming language. Data Scientists, AI and Machine Learning Engineers prefer Python training in Austin for enhancing their career. Programmers with this Python course in Austin earn an annual salary of $115,000. 
               </div>
               <div className='hidden md:hidden lg:hidden xl:block text-base ml-5 mt-5' style={{ fontFamily: "Public Sans",
         fontWeight: "400",
         lineHeight: "24px",
         width:"1000px",
         color:"#686868"}}>
               According to the 2019 StackOverflow developer survey, Python is the second most popular programming language. Data Scientists, AI and Machine Learning Engineers prefer Python training in Austin for enhancing their career. Programmers with this Python course in Austin earn an annual salary of $115,000. 
               </div>

               <div className='flex flex-col justify-center items-center md:flex-row  md:justify-around mb-10 mt-5'>
                    {Box.map((cardbox)=>(
                          <div className='card shadow-lg w-48 h-48 md:w-44 md:h-44 lg:w-56 lg:h-56  xl:w-56 xl:h-56 mt-5' style={{backgroundColor:"#FFFFFF",borderRadius:"10px"}}>

                          <div className='flex flex-col justify-center items-center mt-5 gap-5 md:gap-4 lg:gap-10 xl:gap-10'>
                           <img src={cardbox.image} className='w-32 h-24 md:w-28 md:h-24 lg:w-36 lg:h-28 xl:w-36 xl:h-28' alt=""/>
                           <div className='text-base md:text-base lg:text-xl xl:text-xl' style={{ fontFamily: "Public Sans",
           fontWeight: "600",
           color:"#D42B4D"}}>{cardbox.content}</div>
                          </div>
                      </div>
                    ))}
                   
                  
               </div>
           
             
          </div>


     </div>
</div>
  )
}

export default Benifits
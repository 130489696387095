import React from 'react'

import Pythontool from "../Assests/pythontool 1.png"
import Pandas from "../Assests/pandas 1.png"
import Linux from "../Assests/linux 1.png"
import AWS from "../Assests/aws 1.png"
import Azure from "../Assests/adev 1.png"
import Django from "../Assests/django 1.png"
import Flask from "../Assests/flask 1.png"
import Jupyter from "../Assests/jupiter 1.png"
import Slack from "../Assests/slack 1.png"
import  Github from "../Assests/githubgl 1.png"

function Tools() {

  return (
     <div className="w-full h-max bg-white ">
      <div className="flex flex-col justify-center items-center mt-10 gap-10 mb-10">
        <div
          className="text-2xl md:text-2xl lg:text-2xl xl:text-3xl mt-5"
          style={{
            color: "#28A992",
            fontFamily: "Public sans",
            fontStyle: "normal",
            fontWeight: "600",
          }}
        >
          Tools and Platforms
        </div>
        <div className="grid justify-items-center md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-5 md:gap-5  ">

          <img src={Pythontool} style={{width:"90px",height:"90px",top:"2326px",left:"231px"}}  alt=""/>
          <img src={Pandas}  style={{width:"120px",height:"70px",top:"2320px",left:"423px"}}  alt="" />
          <img src={Linux} style={{width:"160px",height:"75px",top:"2321px",left:"642px"}}  alt=""/>
          <img src={AWS} style={{width:"110px",height:"50px",top:"2357px",left:"916px"}}  alt=""/>
          <img src={Azure} style={{width:"123px",height:"58px",top:"2327px",left:"1105px"}}  alt=""/>

    
        </div>
        <div className="grid justify-items-center md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-5 md:gap-0 ">

          <img src={Django} style={{width:"160px",height:"71px",top:"2455px",left:"183px"}}  alt="" />
          <img src={Flask}  style={{width:"74px",height:"66px",top:"2443px",left:"401px"}}  alt="" />
          <img src={Jupyter} style={{width:"66px",height:"75px",top:"2423px",left:"536px"}}  alt=""/>
          <img src={Slack} style={{width:"146px",height:"55px",top:"2468px",left:"700px"}}  alt=""/>
          <img src={Github} style={{width:"114px",height:"64px",top:"2436px",left:"1115px"}} alt=""/>

    
        </div>
      </div>
    </div>
  )
}

export default Tools